<template>
  <v-container fluid class="text-center">
    <Loader :loading="loading" />
    <Table-Container title="Grupos" :exportInfo="items">
      <template v-slot:buttons>
        <Basic-Btn text="Agregar" color="primary" icon="mdi-plus" @click="dialogs.new = true" :loading="loading" />
        <Basic-Btn text="Actualizar" color="success" icon="mdi-refresh" @click="refresh()" :loading="loading" />
      </template>
      <template v-slot:search>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" class="mx-2" single-line hide-details maxlength="150"></v-text-field>
      </template>
      <v-data-table v-model="selected" :headers="headers" :items="items" :loading="loading" :search="search" :footer-props="{ 'items-per-page-text': 'Elementos por página', 'items-per-page-options': [20, 50, -1] }" :items-per-page="10" item-key="id" class="elevation-1" dense>
        <v-progress-linear slot="progress" color="secondary" indeterminate></v-progress-linear>
        <template v-slot:[`item.zona`]="{ item }">
          {{ item.zona ? item.zona.nombre : "Sin asignar" }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left
            ><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="primary" dark v-bind="attrs" v-on="on" :disabled="loading" @click="view(item)">mdi-pencil</v-icon></template
            ><span>Editar</span></v-tooltip
          >
          <v-tooltip top
            ><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" :color="item.activo ? 'error' : 'success'" dark v-bind="attrs" v-on="on" :disabled="loading" @click="updateStatus(item.idgrupo)">mdi-home-group</v-icon></template
            ><span>{{item.activo ? 'Desactivar' : 'Activar'}}</span></v-tooltip
          >
          <v-tooltip right
            ><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="error" dark v-bind="attrs" v-on="on" :disabled="loading" @click="remove(item.idgrupo)">mdi-delete</v-icon></template
            ><span>Eliminar</span></v-tooltip
          >
        </template>
        <template v-slot:[`item.activo`]="{ item }">
          <v-chip class="ma-0" :color="item.activo ? 'success' : 'error'" dense outlined small>
            {{ item.activo ? "Activo" : "Inactivo" }}
          </v-chip>
        </template>
        <template v-slot:[`item.encargado.detalles_usuarios[0].nombre`]="{ item }">
          {{ item.encargado && item.encargado.detalles_usuarios[0].nombre ? item.encargado.detalles_usuarios[0].nombre + " " + item.encargado.detalles_usuarios[0].apellido_paterno + " " + item.encargado.detalles_usuarios[0].apellido_materno : "Encargado sin asignar" }}
        </template>
        <template v-slot:[`item.supervisor.detalles_usuarios[0].nombre`]="{ item }">
          {{ item.supervisor && item.supervisor.detalles_usuarios[0].nombre ? item.supervisor.detalles_usuarios[0].nombre + " " + item.supervisor.detalles_usuarios[0].apellido_paterno + " " + item.supervisor.detalles_usuarios[0].apellido_materno : "Supervisor sin asignar" }}
        </template>
        <template v-slot:[`item.coordinador.detalles_usuarios[0].nombre`]="{ item }">
          {{ item.coordinador && item.coordinador.detalles_usuarios[0].nombre ? item.coordinador.detalles_usuarios[0].nombre + " " + item.coordinador.detalles_usuarios[0].apellido_paterno + " " + item.coordinador.detalles_usuarios[0].apellido_materno : "Coordinador sin asignar" }}
        </template>
        <template v-slot:[`item.validador.detalles_usuarios[0].nombre`]="{ item }">
          {{ item.validador && item.validador.detalles_usuarios[0].nombre ? item.validador.detalles_usuarios[0].nombre + " " + item.validador.detalles_usuarios[0].apellido_paterno + " " + item.validador.detalles_usuarios[0].apellido_materno : "Validador sin asignar" }}
        </template>
        <template v-slot:[`footer.page-text`]="props"> Página {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }} </template>
        <template v-slot:no-data> No hay información disponible </template>
        <template v-slot:no-results> No se obtuvieron resultados </template>
      </v-data-table>
    </Table-Container>
    <Dialog-Form :estate="dialogs.new" title="Nuevo grupo">
      <template v-slot:buttons>
        <Basic-Btn text="Cancelar" color="error" icon="mdi-close" @click="cancel()" :loading="loading" />
        <Basic-Btn text="Guardar" color="success" icon="mdi-check" @click="save()" :loading="loading" />
      </template>
      <v-form ref="formNew" lazy-validation>
        <v-row>
          <v-col cols="12" md="12">
            <v-text-field v-model="forms.new.nombre" label="Nombre" prepend-icon="mdi-account-group" :rules="rules.nombre" :disabled="loading" outlined dense required></v-text-field>
          </v-col>
          <v-col cols="12" md="12">
            <v-autocomplete v-model="forms.new.encargado" :items="users" item-text="email" item-value="idusuario" label="Encargado" prepend-icon="mdi-account" persistent-hint no-data-text="No se encontraron resultados" outlined dense required></v-autocomplete>
          </v-col>
          <v-col cols="12" md="12">
            <v-autocomplete v-model="forms.new.supervisor" :items="users" item-text="email" item-value="idusuario" label="Supervisor" prepend-icon="mdi-account" persistent-hint no-data-text="No se encontraron resultados" outlined dense required></v-autocomplete>
          </v-col>
          <v-col cols="12" md="12">
            <v-autocomplete v-model="forms.new.coordinador" :items="users" item-text="email" item-value="idusuario" label="Coordinador" prepend-icon="mdi-account" persistent-hint no-data-text="No se encontraron resultados" outlined dense required></v-autocomplete>
          </v-col>
          <v-col cols="12" md="12">
            <v-autocomplete v-model="forms.new.validador" :items="users" item-text="email" item-value="idusuario" label="Validador" prepend-icon="mdi-account" persistent-hint no-data-text="No se encontraron resultados" outlined dense required></v-autocomplete>
          </v-col>
          <v-col cols="12" md="12">
            <v-autocomplete v-model="forms.new.zona" :items="zones" item-text="nombre" item-value="idzona" label="Zona" prepend-icon="mdi-map-marker-radius-outline" persistent-hint no-data-text="No se encontraron resultados" outlined dense required></v-autocomplete>
          </v-col>
        </v-row>
      </v-form>
    </Dialog-Form>
    <Dialog-Form :estate="dialogs.update" title="Actualizar grupo">
      <template v-slot:buttons>
        <Basic-Btn text="Cancelar" color="error" icon="mdi-close" @click="cancel()" :loading="loading" />
        <Basic-Btn text="Actualizar" color="success" icon="mdi-check" @click="update()" :loading="loading" />
      </template>
      <v-form ref="formUpdate" lazy-validation>
        <v-row>
          <v-col cols="12" md="12">
            <v-text-field v-model="forms.update.nombre" label="Nombre" prepend-icon="mdi-account-group" :rules="rules.nombre" :disabled="loading" outlined dense required></v-text-field>
          </v-col>
          <v-col cols="12" md="12">
            <v-autocomplete v-model="forms.update.encargado" :items="users" item-text="email" item-value="idusuario" label="Encargado" prepend-icon="mdi-account" persistent-hint no-data-text="No se encontraron resultados" outlined dense required></v-autocomplete>
          </v-col>
          <v-col cols="12" md="12">
            <v-autocomplete v-model="forms.update.supervisor" :items="users" item-text="email" item-value="idusuario" label="Supervisor" prepend-icon="mdi-account" persistent-hint no-data-text="No se encontraron resultados" outlined dense required></v-autocomplete>
          </v-col>
          <v-col cols="12" md="12">
            <v-autocomplete v-model="forms.update.coordinador" :items="users" item-text="email" item-value="idusuario" label="Coordinador" prepend-icon="mdi-account" persistent-hint no-data-text="No se encontraron resultados" outlined dense required></v-autocomplete>
          </v-col>
          <v-col cols="12" md="12">
            <v-autocomplete v-model="forms.update.validador" :items="users" item-text="email" item-value="idusuario" label="Validador" prepend-icon="mdi-account" persistent-hint no-data-text="No se encontraron resultados" outlined dense required></v-autocomplete>
          </v-col>
          <v-col cols="12" md="12">
            <v-autocomplete v-model="forms.update.zona" :items="zones" item-text="nombre" item-value="idzona" label="Zona" prepend-icon="mdi-map-marker-radius-outline" persistent-hint no-data-text="No se encontraron resultados" outlined dense required></v-autocomplete>
          </v-col>
        </v-row>
      </v-form>
    </Dialog-Form>
    <Delete-Message :estate="dialogs.remove" msg="¿Realmente desea eliminar el grupo?">
      <template v-slot:buttons>
        <Basic-Btn text="Cancelar" color="disabled" icon="mdi-close" @click="cancel()" :loading="loading" />
        <Basic-Btn text="Eliminar" color="error" icon="mdi-check" @click="remove()" :loading="loading" />
      </template>
    </Delete-Message>
    <BasicAlert :active.sync="alert.active" :msg="alert.msg" :color="alert.color" />
  </v-container>
</template>

<script>
import services from "@/utils/services";
import { Loader, DialogForm, BasicAlert, TableContainer, BasicBtn, DeleteMessage } from "@/components";

export default {
  name: "group",
  components: {
    Loader,
    DialogForm,
    BasicAlert,
    TableContainer,
    BasicBtn,
    DeleteMessage,
  },
  data: () => ({
    loading: false,
    alert: {
      active: false,
      color: "error",
      msg: "",
    },
    search: "",
    headers: [
      { text: "ID", align: "left", sortable: true, value: "idgrupo" },
      { text: "Nombre", align: "left", sortable: true, value: "nombre" },
      { text: "Zona", align: "left", sortable: true, value: "zona" },
      { text: "Encargado", align: "left", sortable: true, value: "encargado.detalles_usuarios[0].nombre" },
      { text: "Supervisor", align: "left", sortable: true, value: "supervisor.detalles_usuarios[0].nombre" },
      { text: "Coordinador", align: "left", sortable: true, value: "coordinador.detalles_usuarios[0].nombre" },
      { text: "Validador", align: "left", sortable: true, value: "validador.detalles_usuarios[0].nombre" },
      { text: "Activo", align: "left", sortable: false, value: "activo" },
      { text: "Acciones", align: "left", sortable: false, value: "actions" },
    ],
    items: [],
    users: [],
    zones: [],
    selected: [],
    deleted: null,
    forms: {
      new: {
        nombre: "",
        encargado: "",
        supervisor: "",
        coordinador: "",
        validador: "",
        zona: "",
        nombre: "",
      },
      update: {
        idgrupo: "",
        encargado: "",
        supervisor: "",
        coordinador: "",
        validador: "",
        zona: "",
        nombre: "",
      },
    },
    rules: {
      nombre: [(v) => !!v || "El campo es requerido", (v) => (v && v.length <= 100) || "El campo excede la longitud máxima"],
    },
    dialogs: {
      new: false,
      update: false,
      remove: false,
    },
  }),
  mounted() {
    this.refresh();
  },
  methods: {
    async refresh() {
      this.loading = true;
      this.axios
        .get(services.routes.group)
        .then((response) => {
          this.items = response.data.data;
        })
        .catch((error) => {})
        .finally(() => {
          this.loading = false;
          this.refreshZone();
          this.refreshUser();
        });
    },
    async refreshZone() {
      this.loading = true;
      this.axios
        .get(services.routes.zone)
        .then((response) => {
          this.zones = response.data.data;
        })
        .catch((error) => {})
        .finally(() => {
          this.loading = false;
        });
    },
    async refreshUser() {
      this.loading = true;
      this.axios
        .get(services.routes.user)
        .then((response) => {
          this.users = response.data.data;
        })
        .catch((error) => {})
        .finally(() => {
          this.loading = false;
        });
    },
    cancel() {
      if (this.dialogs.new) {
        this.$refs.formNew.reset();
        for (let key in this.forms.new) {
          this.forms.new[key] = "";
        }
        this.dialogs.new = false;
      } else if (this.dialogs.update) {
        this.dialogs.update = false;
      } else if (this.dialogs.remove) {
        this.dialogs.remove = false;
      }
    },
    save() {
      if (this.$refs.formNew.validate()) {
        this.loading = true;
        let valid = true
         for (let key in this.forms.new) {
          if(this.forms.new[key] == ""){
            valid = false
          }
        }
        if(valid){
        let data = JSON.parse(JSON.stringify(this.forms.new));
        this.axios
          .post(services.routes.group, { data })
          .then((response) => {
            this.cancel();
            this.refresh();
            this.alert.active = true;
            this.alert.color = "success";
            this.alert.msg = "Grupo creado exitosamente";
          })
          .catch((error) => {
            this.alert.active = true;
            this.alert.color = "error";
            this.alert.msg = error.response.data.message || "Servicio no disponible";
          })
          .finally(() => {
            this.loading = false;
          });
          }else{
            this.loading = false;
            this.alert.active = true;
            this.alert.color = "error";
            this.alert.msg = "Informacion incompleta";
          }
      } else {
        this.alert.active = true;
        this.alert.color = "error";
        this.alert.msg = "La información es incorrecta";
      }
    },
    view(data) {
      this.forms.update = JSON.parse(JSON.stringify(data));
      this.forms.update.encargado = data.encargado && data.encargado.idusuario ? data.encargado.idusuario : null;
      this.forms.update.supervisor = data.supervisor && data.supervisor.idusuario ? data.supervisor.idusuario : null;
      this.forms.update.coordinador = data.coordinador && data.coordinador.idusuario ? data.coordinador.idusuario : null;
      this.forms.update.validador = data.validador && data.validador.idusuario ? data.validador.idusuario : null;
      this.forms.update.zona = data.zona && data.zona.idzona ? data.zona.idzona : null;
      this.dialogs.update = true;
    },
    update() {
      if (this.$refs.formUpdate.validate()) {
        this.loading = true;
        let data = JSON.parse(JSON.stringify(this.forms.update));
        this.axios
          .put(services.routes.group, { data })
          .then((response) => {
            this.cancel();
            this.refresh();
            this.alert.active = true;
            this.alert.color = "success";
            this.alert.msg = "Grupo actualizado exitosamente";
          })
          .catch((error) => {
            this.alert.active = true;
            this.alert.color = "error";
            this.alert.msg = error.response.data.message || "Servicio no disponible";
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.alert.active = true;
        this.alert.color = "error";
        this.alert.msg = "La información es incorrecta";
      }
    },
    updateStatus(id) {
      this.axios
        .put(services.routes.group + '/updateStatus', { data: {  idgrupo: id  } })
        .then(() => {
          this.refresh();
          this.alert.active = true;
          this.alert.color = "success";
          this.alert.msg = "Grupo editado exitosamente";
        })
        .catch((error) => {
          this.alert.active = true;
          this.alert.color = "error";
          this.alert.msg = error.response.data.message || "Servicio no disponible";
        })
        .finally(() => {
          this.loading = false;
        });
    },
    remove(id) {
      if (!id) {
        this.loading = true;
        let id = this.deleted;
        this.axios
          .delete(services.routes.group, { data: { data: { idgrupo: this.deleted } } })
          .then((response) => {
            this.cancel();
            this.refresh();
            this.alert.active = true;
            this.alert.color = "success";
            this.alert.msg = "Grupo eliminada exitosamente";
          })
          .catch((error) => {
            this.alert.active = true;
            this.alert.color = "error";
            this.alert.msg = error.response.data.message || "Servicio no disponible";
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.deleted = id;
        this.dialogs.remove = true;
      }
    },
  },
};
</script>

<style scoped>
</style>
